import React from 'react'
import styled from 'styled-components'



// Define CSS Grid Components
const GRouteMapContentGrid = styled.div`
`;
const SgRouteMapContentGrid = styled.div`
    margin: 0 auto;
    max-width: 1360px;
    margin-bottom: 250px;
    .btn-back-to-shipment-details {
      color: #0033a1;
      cursor: pointer;
      text-decoration: none;
      background: transparent;
      border: transparent;
      font-size: 14px !important;
      &:hover {
        color: #26c1fb;
      }
    }
    img {
      max-width: 100%;
      height: auto;
      background-size: cover;
    }
`;

const EchoShipGridComponentContent = (props) => {
  return (

    <GRouteMapContentGrid>
        <SgRouteMapContentGrid>
            <button className="btn-back-to-shipment-details">
                Back to Shipment Details
            </button>
            <img
                className="carrier-thumbnail"
                src={props.image} 
            />
        </SgRouteMapContentGrid>
    </GRouteMapContentGrid>

  )
}

export default EchoShipGridComponentContent;